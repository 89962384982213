<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import router from "@/router/index.js";

export default {
  page: {
    title: "Réservation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Réservations",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historiques",
          active: true,
        },
      ],
      ordersData: [],
      transaction: {
        total: "",
        echouee: "",
        reussie: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "profil", sortable: true, label: "Passager" },
        { key: "tel", sortable: true, label: "Numéro " },
        { key: "conducteur", sortable: true, label: "Conducteur" },

        { key: "telConducteur", sortable: true, label: "Numéro " },
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "siege", label: "Référence de Siège" },
        { key: "depart", sortable: true, label: "Départ" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "moyen", sortable: true, label: "Moyen" },
        { key: "date", sortable: true, label: "Date paiement" },
        { key: "place", sortable: true, label: "Place.s" },
        { key: "about", label: "Statut" },
      ],

      fieldVip: [
        { key: "utilisateur", label: "Utilisateur" },
        { key: "telephone", sortable: true, label: "Téléphone" },
        { key: "date_debut", label: "Début" },
        { key: "date_fin", sortable: true, label: "Date fin" },
        { key: "montant", label: "Montant" },
        { key: "status", label: "Status" },

        //{ key: "action", label: "Actions" },
      ],
      transactionData: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDataHistorique: [],
      recherche: "",
      parBon: 500,
      vipList: [],
      vipListeLength: 1,
      totalRowsVip: 0
    };
  },

  async mounted() {
    const historiqueTransac = await apiRequest(
      "GET",
      "admin/transaction/info",
      undefined,
      false
    );

    if (historiqueTransac && historiqueTransac.data) {
      this.transaction.total = historiqueTransac.data.total
        ? historiqueTransac.data.total
        : "0";
      this.transaction.echouee = historiqueTransac.data.echecs
        ? historiqueTransac.data.echecs
        : "0";
      this.transaction.reussie = historiqueTransac.data.reussies
        ? historiqueTransac.data.reussies
        : "0";
    }

    const listeTransaction = await apiRequest(
      "GET",
      "admin/transactions",
      undefined,
      false
    );

    console.log("listeTransaction.data",listeTransaction.data);


    if (listeTransaction && listeTransaction.data) {
      console.log(listeTransaction.data);
      const transactListTable = listeTransaction.data.map((transaction) => {

        var siegeData = " ";



          transaction.sieges.map((name) => {
            if (name !== null && name !== undefined) {

              console.log("name", name)

              siegeData += `${name} - `

            } else  {
              siegeData.push("non défini")
            }

          })
        if (transaction.client != null && transaction.conducteur != null) {
          return {
            id: transaction.client.id,
            siege:siegeData,
            idConducteur:transaction.conducteur.personne.id,
            profil:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            conducteur:
              transaction.conducteur.personne.lastname +
              " " +
              transaction.conducteur.personne.firstname,
            tel:
              transaction.client.personne.tel &&
                transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                transaction.client.personne.tel
                : "",
            telConducteur:
              transaction.conducteur.personne.tel &&
                transaction.conducteur.personne.tel != ""
                ? transaction.conducteur.personne.indicatifTel +
                transaction.conducteur.personne.tel
                : "",
            trajet: transaction.trajet
              ? transaction.trajet.lieuDepart.nom +
              " >> " +
              transaction.trajet.lieuArrivee.nom
              : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.trajet ? transaction.trajet.prix : "",
            moyen: transaction.moyen,
            depart: transaction.trajet
              ? new Date(transaction.trajet.dateHeureDepart).toLocaleString(
                "fr-FR"
              )
              : "",
            place: transaction.place,
            about: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
        }
      });
      transactListTable.forEach((element) => {
        if (element != null) this.transactionData.push(element);
      });


    }
    this.totalRows = this.transactionData.length

    this.paiementVip()
  },

  methods: {
    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank','noreferrer');
    },

    goToDriverDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank','noreferrer');
    },

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/transactions?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const TransactListTable = data.data.map((transaction) => {
          //if (transaction.trajet != null) {
          return {
            id: transaction.client.id,
            profil:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.tel &&
                transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                transaction.client.personne.tel
                : "",
            trajet: transaction.trajet
              ? transaction.trajet.lieuDepart.nom +
              " >> " +
              transaction.trajet.lieuArrivee.nom
              : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.trajet ? transaction.trajet.prix : "",
            moyen: transaction.moyen,
            depart: transaction.trajet
              ? new Date(transaction.trajet.dateHeureDepart).toLocaleString(
                "fr-FR"
              )
              : "",
            place: transaction.place,
            about: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
          //}
        });
        this.newDataHistorique = TransactListTable;

        this.userChargeLoader = false;
      }
    },

    async userSearch() {
      console.log(this.recherche);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/transactions-recherche?mots=" + this.recherche,

        undefined,
        false
      );
      if (dataSearch && dataSearch.data) {
        const TransactListTable = dataSearch.data.map((transaction) => {
          //if (transaction.trajet != null) {
          return {
            id: transaction.client.id,
            profil:
              transaction.client.personne.lastname +
              " " +
              transaction.client.personne.firstname,
            tel:
              transaction.client.personne.tel &&
                transaction.client.personne.tel != ""
                ? transaction.client.personne.indicatifTel +
                transaction.client.personne.tel
                : "",
            trajet: transaction.trajet
              ? transaction.trajet.lieuDepart.nom +
              " >> " +
              transaction.trajet.lieuArrivee.nom
              : "",
            date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
            montant: transaction.trajet ? transaction.trajet.prix : "",
            moyen: transaction.moyen,
            depart: transaction.trajet
              ? new Date(transaction.trajet.dateHeureDepart).toLocaleString(
                "fr-FR"
              )
              : "",
            place: transaction.place,
            about: transaction.statut == "PAYER" ? "PAID" : transaction.statut,
          };
          //}
        });
        this.newDataHistorique = TransactListTable;

        this.userChargeLoader = false;
      }
    },


    async paiementVip() {
      const vip = await apiRequest(
        "GET",
        "vip/list-vip",
        undefined,
        false
      );
      console.log(vip);

      if (vip && vip.data) {
        console.log(vip.data);
        this.totalValue = vip.data.total
        const vipTable = vip.data.map((vipData) => {
          return {
            utilisateur: vipData.personne.lastname +
              " " +
              vipData.personne.firstname,
            telephone: `${vipData.personne.indicatifTel} ${vipData.personne.tel}`,
            status: vipData.status_paiement,
            date_debut: new Date(vipData.dateDebut).toLocaleString("fr-FR"),
            date_fin: new Date(vipData.dateFin).toLocaleString("fr-FR"),

            montant: vipData.montant


          }
        });
        this.vipList = vipTable
        this.vipListeLength = this.vipList.length



        this.totalRowsVip = this.vipData.length;
        this.totalValue = this.vipData.length;
      }


    },




    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    onFiltered(filteredItems) {
      this.vipListeLength = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row d-flex justify-content-start mx-4">
              <div class="col-md-2">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-2">Paiement total</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.total }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card header-class="bg-transparent border-primary" class="border border-info">
                  <template v-slot:header>
                    <h5 class="my-0 mb-2">Paiement réussi</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.reussie }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-2">
                <b-card header-class="bg-transparent border-primary" class="border border-warning">
                  <template v-slot:header>
                    <h5 class="my-0 mb-2">Paiement échoué</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ transaction.echouee }}</h1>
                  </div>
                </b-card>
              </div>
              <!-- <div class="col-md-6">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <div>
                    <ul>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">PAID :</span>
                        Total paiement de réservation réussi
                      </li>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">Failed / ERROR :</span>
                        Solde insuffisant ou réservations rejetées ou numéro
                        invalide
                      </li>
                      <li>
                        <span class="text-uppercase" style="color: black; font-weight: bold">Initial :</span>
                        Total paiement de réservation initialisé
                      </li>
                    </ul>
                  </div>
                  <p>
                    Le statut
                    <span class="text-uppercase" style="color: black; font-weight: bold">Failed</span>
                    ne signifie pas de manière systématique que le paiement pour la réservation a
                    échoué. Cela voudrait simplement dire que la dernière valeur
                    enregistrée du statut suite à la vérification est
                    <span class="text-uppercase" style="color: black; font-weight: bold">failed</span>
                  </p>
                </b-card>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="transactionData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-end align-items-center p-2">
              <!--   <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
             
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ this.transaction.reussie }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition> -->
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Paiement de réservation covoiturage : {{ totalRows }}
                  </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3"></div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                        @keyup.enter="userSearch" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="newDataHistorique.length != 0
                      ? newDataHistorique
                      : transactionData
                    " :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                    @filtered="onFiltered" >
                    <template v-slot:cell(about)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger':
                          `${row.value}` === 'ERROR'
                            ? 'badge-soft-danger'
                            : `${row.value}` === 'FAILED',
                        'badge-soft-success': `${row.value}` === 'PAID',
                        'badge-soft-warning': `${row.value}` === 'INITIAL',
                      }">
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(conducteur)="row"   >
                      <div class="row" @click="goToDriverDetail(row.item.id)" >
                        <div class="col-sm-10" style="text-align: center;width:150px;">{{ row.value }}</div>
                        <div class="col-sm-2" style="text-align: center ;text-wrap: nowrap; padding:0px;margin:0px;"> <i class="fas fa-eye"></i></div>
                         
                    </div>
                    </template>
                    <template v-slot:cell(profil)="row"   >
                      <div class="row" @click="goToUserDetail(row.item.id)" >
                        <div class="col-sm-10" style="text-align: center;width:150px;">{{ row.value }}</div>
                        <div class="col-sm-2" style="text-align: center ;text-wrap: nowrap; padding:0px;margin:0px;"> <i class="fas fa-eye"></i></div>
                         
                    </div>
                    </template>
                    <template v-slot:cell(telConducteur)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">

                        <b-pagination v-model="currentPage" :total-rows="vipListeLength" :per-page="perPage"
                          :aria-controls="trajet"></b-pagination>
                      </ul>
                    </div>
                    <!--  <transition mode="out-in">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                      
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ this.transaction.reussie }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button
                              class="myBouton"
                              @click="firstVal()"
                              :disabled="startValue == 1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Prem"
                            >
                              <ion-icon
                                name="play-skip-back-outline"
                              ></ion-icon>
                            </button>
                            <button
                              type="button"
                              class="myBouton"
                              @click="decrement()"
                              :disabled="endValue == parBon"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Préc"
                            >
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="increment()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Suiv"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="chevron-forward-outline"
                              ></ion-icon>
                            </button>
                            <button
                              class="myBouton"
                              @click="incrementToLast()"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Dern"
                              :disabled="endValue >= totalValue"
                            >
                              <ion-icon
                                name="play-skip-forward-outline"
                              ></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition> -->
                  </div>
                </div>
              </b-tab>

              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Paiement vip: {{ totalRowsVip }}
                  </a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3"></div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                        @keyup.enter="userSearch" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="vipList
                    " :fields="fieldVip" responsive="sm" :per-page="perPage" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                    @filtered="onFiltered" @row-clicked="goToDetail">
                    <template v-slot:cell(about)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger':
                          `${row.value}` === 'ERROR'
                            ? 'badge-soft-danger'
                            : `${row.value}` === 'FAILED',
                        'badge-soft-success': `${row.value}` === 'PAID',
                        'badge-soft-warning': `${row.value}` === 'INITIAL',
                      }">
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                    <template v-slot:cell(telConducteur)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>

                    <template v-slot:cell(status)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger':
                          `${row.value}` === 'false'
                            ? 'badge-soft-danger'
                            : `${row.value}` === 'false',
                        'badge-soft-success': `${row.value}` === 'true',

                      }">
                        {{ row.value == true ? 'PAYÉ' : 'ECHOUÉ' }}
                      </div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                    <transition mode="out-in">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ this.transaction.reussie }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                              data-placement="bottom" title="Prem">
                              <ion-icon name="play-skip-back-outline"></ion-icon>
                            </button>
                            <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == parBon"
                              data-toggle="tooltip" data-placement="bottom" title="Préc">
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                              title="Suiv" :disabled="endValue >= totalValue">
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                              data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                              <ion-icon name="play-skip-forward-outline"></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
